import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/components/services/crud.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
    public urlImageHD = environment.urlImageCorsoHD;
    public elencoCorsiInHome : any=[];
    public idCorsista : string="";

    constructor(
        private crudService: CrudService
    ) { }


  ngOnInit(): void {
    let detailUser=JSON.parse(localStorage.getItem('user_id'));
    if(detailUser) {
        this.idCorsista=detailUser['id_corsista']
    }

    this.loadCorsiInHome(this.idCorsista);
  }

  loadCorsiInHome(idCorsista) {
    this.crudService.api_get_ElencoCorsiInHome(idCorsista).subscribe(productData => {
        this.elencoCorsiInHome=productData;
    },(err)=>{
        //console.log(err);
        }, ()=>{
    }
);
}

}
