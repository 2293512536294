<div class="row align-items-center">
    <div class="col-lg-2 col-6 col-sm-3 col-md-3"></div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/forma-temp.jpg" style="width: 100px" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/calabria-logo.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/client_logo_ISO_9001.jpg" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3 d-none">
        <div class="single-partner-item">
            <img src="assets/img/partner/logo-regione-campania.jpg" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3">
        <div class="single-partner-item">
            <img src="assets/img/partner/Marzano_di_Nola-Stemma.png" alt="image">
        </div>
    </div>
    <div class="col-lg-2 col-6 col-sm-3 col-md-3 d-none">
        <div class="single-partner-item">
            <img src="assets/img/partner/Regione Molise.jpg" alt="image">
        </div>
    </div>
    <div class="col-lg-1 col-6 col-sm-3 col-md-3"></div>
</div>
