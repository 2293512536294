<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Login</a></li>
                <li>Accesso alla piattaforma</li>
            </ul>
            <h2>Accedi ai tuoi corsi</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-authentication-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="login-form" style="border: 0px; box-shadow: none;">
                    <h2 class="mb-0">Login</h2>
                    <small>effettua il login per accedere ai tuoi corsi</small>
                    <form class="mt-4">
                        <div class="form-group">
                            <label>Username</label>
                            <input type="text" #username_small id="username_small" class="form-control" placeholder="inserisci il tuo username">
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" #password_small id="password_small" class="form-control" placeholder="inserisci la tua password">
                        </div>
                        <div class="row align-items-center d-none">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                <p>
                                    <input type="checkbox" id="test2">
                                    <label for="test2">Remember me</label>
                                </p>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a routerLink="/" class="lost-your-password">Lost your password?</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 text-center">
                            <button (click)="checkLoginSmall();" class="mt-5">Effettua il login!</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="register-form">
                    <h2>Reset Password</h2>
                    <form>
                        <div class="form-group">
                            <label>Username</label>
                            <input type="text" id="usernameReset" class="form-control" placeholder="inserisci il tuo username">
                        </div>
                        <div class="form-group">
                            <label>e-mail</label>
                            <input type="email" id="emailReset" class="form-control" placeholder="inserisci la tua e-mail">
                        </div>
                        <div class="form-group d-none">
                            <label>Password</label>
                            <input type="password" class="form-control" placeholder="Password">
                        </div>
                        <p class="description">per il recupero della password inserisci il tuo username e l'indirizzo e-mail utilizzato nella registrazione.</p>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <button (click)="resetPassword();">Reset Password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
