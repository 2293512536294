<div class="main-banner" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
                    <h1>Semplificati la vita con i nostri Corsi Online</h1>
                    <p>Direttamente da casa o dal tuo ufficio, comodamente seduto, senza spostarti in auto, in moto o con i mezzi pubblici!</p>
                    <p><b>laformazioneadistanza.it</b> nasce dall’esperienza di giovani professionisti nel settore della formazione professionale che hanno realizzato questa piattaforma per la formazione a distanza specializzandosi con i corsi per la sicurezza sugli ambienti e sui luoghi di lavoro</p>
                    <a routerLink="/contatti" class="default-btn"><i class="flaticon-user"></i>Registrati è Gratuito<span></span></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-courses-list">
                    <div *ngIf="elencoCorsiInHome[0]" class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box">
                                <div class="courses-image">
                                    <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[0].tag}}" class="d-block image">
                                        <img style="height:260px; object-fit: cover; width: 100%;"src="{{urlImage+elencoCorsiInHome[0].image}}" alt="image">
                                    </a>
                                    <div class="price shadow">{{elencoCorsiInHome[0].durata}}h</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/dettaglio-corso/{{elencoCorsiInHome[0].tag}}">{{elencoCorsiInHome[0].titolo}}</a></h3>
                                    <p class="textDescrizione">{{elencoCorsiInHome[0].descrizione}}</p>
                                    <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[0].tag}}" class="default-btn"><i class="flaticon-user"></i>Visualizza<span></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box">
                                <div class="courses-image">
                                    <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[1].tag}}" class="d-block image">
                                        <img style="height:260px; object-fit: cover; width: 100%;" src="{{urlImage+elencoCorsiInHome[1].image}}" alt="image">
                                    </a>
                                    <div class="price shadow">{{elencoCorsiInHome[1].durata}}h</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/dettaglio-corso/{{elencoCorsiInHome[0].tag}}">{{elencoCorsiInHome[1].titolo}}</a></h3>
                                    <p class="textDescrizione">{{elencoCorsiInHome[1].descrizione}}</p>
                                    <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[1].tag}}" class="default-btn"><i class="flaticon-user"></i>Visualizza<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="banner-shape1"><img src="assets/img/banner-shape1.png" alt="image"></div>
                    <div class="banner-shape2"><img src="assets/img/banner-shape2.png" alt="image"></div>
                    <div class="banner-shape3"><img src="assets/img/banner-shape3.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</div>
