<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" alt="logo"></a>
                    <p>Lavorare per apportare cambiamenti significativi nell'apprendimento online facendo ricerche approfondite per la preparazione del curriculum del corso, il coinvolgimento degli studenti e aspettando con impazienza l'istruzione flessibile!</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Link</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Login</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Discenti</a></li>
                        <li><a routerLink="/">Docenti</a></li>
                        <li><a routerLink="/">Partners</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Dove Siamo</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Corso Umberto I 174, Napoli, Italia</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+393496751698">Mobile: (+39) 349-6751698</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info@laformazioneadistanza.it">info@laformazioneadistanza.it</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">Lunedì-Venerdì: 09:00÷18:00</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2022 "laformazioneadistanza" piattaforma Discentya realizzata da <a target="_blank" href="https://www.algoritmicalab.it/">Algoritmica Lab</a></p>
                </div>
                <div class="col-lg-4 col-md-4">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-of-service">Termini di Condizione</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
