<div class="gym-home-area" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="gym-banner-slides">
        <owl-carousel-o [options]="gymBannerSlides">
            <ng-template carouselSlide *ngFor="let rowCorso of elencoCorsiInHome;">
                <div class="gym-banner-item">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div *ngIf="rowCorso.durata>0" class="price">{{rowCorso.durata}}h</div>
                                <div class="gym-banner-image" style="padding-left: 0px;">
                                    <img src="{{urlImage+rowCorso.image}}" style="object-fit: cover; border-radius: 3px;" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="gym-banner-content">
                                    <div id="divMiur">
                                        <img class="imgMiur" *ngIf="rowCorso.isMiur==1" src="assets/img/miur.png">
                                    </div>
                                    <h1>{{rowCorso.titolo}}</h1>
                                    <p class="textDescrizione">{{rowCorso.descrizione}}</p>
                                    <a routerLink="/dettaglio-corso/{{rowCorso.tag}}" class="default-btn"><i class="flaticon-user"></i>Visualizza<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
