<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=Corso%20Umberto%20I%20174,%20Napoli,%20Italia&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>La Nostra Storia</h2>
                    <p class="d-none">Centro di formazione accreditato dalla Regione Campania, promuove corsi e master altamente qualificanti in ogni settore. Il nostro intento è quello di creare, adeguare, mantenere e modificare nel tempo le competenze e le specializzazioni professionali, in virtù delle sempre diverse esigenze di un mercato dinamico ed in continua evoluzione; perseguiamo fermamente il fine di qualificare e soprattutto abbreviare la distanza tra formazione ed inserimento lavorativo.</p>
                    <p><b>laformazioneadistanza.it</b> nasce dall’esperienza di giovani professionisti nel settore della formazione professionale che hanno realizzato questa piattaforma per la formazione a distanza specializzandosi con i corsi per la sicurezza sugli ambienti e sui luoghi di lavoro Testo Unico D.Lgs. 81/08 (Ex D.Lgs. 626/94), per i corsi di alimentarista (ex libretto sanitario) Regolamenti Comunitari n. 852/04 e 853/04 e per le abilitazioni/qualifiche dei repertori Regionali </p>
                    <p>
                        <b>La Nostra Mission</b>
                        L’intento della laformazioneadistanza.it è quello di creare, adeguare, mantenere e modificare nel tempo le competenze e le specializzazioni professionali, in virtù delle sempre diverse esigenze di un mercato dinamico ed in continua evoluzione; perseguiamo fermamente il fine di qualificare e soprattutto abbreviare la distanza tra formazione ed inserimento lavorativo.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Cosa Facciamo</h2>
                    <p>Nel nostro Claim è racchiusa la nostra mission, offriamo ai giovani percorsi formativi che siano uno strumento valido, rapido ed efficace per inserirsi a pieno titolo nel mondo del lavoro attraverso l’acquisizione di competenze essenzialmente pratiche ed immediatamente spendibili in un contesto professionale. Supportare i nostri allievi nella costruzione della loro professionalità e quindi della loro vita è la missione che la ci persegue con la sua attività di ricerca e con i suoi percorsi formativi.</p>
                    <p>Le tipologie corsuali sono strutturate in modo diversiﬁcato garantendo diversi livelli di formazione, interazione e approfondimento con i docenti incaricati, il tutto ﬁnalizzato a una migliore qualità dei corsi medesimi in relazione a un mondo del lavoro in continuo cambiamento ed evoluzione.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Corso Umberto I 174, Napoli, Italia</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:+393496751698">(+39) 349 - 6751698</a></p>
                            <p>e-mail: <a href="mailto:info@laformazioneadistanza.it">info@laformazioneadistanza.it</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" id="sceltaCorso" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
