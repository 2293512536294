import { Component, OnInit, ViewChild } from '@angular/core';
import { CrudService } from '../../services/crud.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute} from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'gestione-autonoma-us',
    templateUrl: './gestione-autonoma.html',
    styleUrls: ['./gestione-autonoma.scss']
})
export class GestioneAutonoma implements OnInit {
    @ViewChild('contactForm') contactForm:NgForm;
    public elencoCorsi: any=[];
    public detailPartner: any=[];
    public qrCode: string="";
    public idCorsistaDaAttivare: string="";
    public idCorsoDaAttivare: string="";
    public isAddedCorsista: string="";
    public titoloCorso: string="";
    public nomeCognomeCorsista: string="";
    fileName = '';


    constructor(
        private crudService: CrudService,
        private actRoute: ActivatedRoute,
        private http: HttpClient
    ) { }

    ngOnInit(): void {
        //mi recupero l'ID del CORSO per ricavare la scheda
        this.qrCode=this.actRoute.snapshot.params['id'];

        var arrayCode=this.qrCode.split("&");
        if(arrayCode.length>1) {
            this.qrCode=arrayCode[0];
            this.idCorsistaDaAttivare=arrayCode[1];
            this.idCorsoDaAttivare=arrayCode[2];
        }
        this.loadPartner(this.qrCode);
    }

    bgImage = [
        {
            img: 'assets/img/register-shape_1.jpg'
        }
    ]

    onFileSelected(event) {
        const file:File = event.target.files[0];
        if (file) {
            (<HTMLInputElement>document.getElementById("fileNameChange")).innerHTML=file.name;
            var arrayFileName = file.name.split(".");
            const formData = new FormData();

            let timeStamp=new Date().valueOf();
            this.fileName=timeStamp.toString()+"."+arrayFileName[arrayFileName.length-1];

            formData.append("file", file);
            formData.append("filename", timeStamp.toString());

            const upload$ = this.http.post(environment.web_api_url_base+"upload.php", formData).subscribe(response=>{
                console.log(response);
            });
        }
    }

    loadCorsi(pag){
        var findCorsista: string;
        this.crudService.api_get_ElencoCorsi(pag, findCorsista, '', '', '').subscribe(productData => {
                this.elencoCorsi=productData;
                this.elencoCorsi.forEach(element => {
                    if(element['id_corso']==this.idCorsoDaAttivare) {
                        this.titoloCorso=element['corso']['titolo'];

                        if(this.idCorsistaDaAttivare!="") {
                            this.addCorsistaInAula();
                        }
                    }
                });
            },(err)=>{
                //console.log(err);
                }, ()=>{
            }
        );
    }

    loadCorsiPartner(idPartner){
        this.crudService.api_get_ElencoCorsiPartner(idPartner).subscribe(productData => {
            console.log(productData);
                this.elencoCorsi=productData;
                this.elencoCorsi.forEach(element => {
                    if(element['id_corso']==this.idCorsoDaAttivare) {
                        this.titoloCorso=element['corso']['titolo'];

                        if(this.idCorsistaDaAttivare!="") {
                            this.addCorsistaInAula();
                        }
                    }
                });
            },(err)=>{
                //console.log(err);
                }, ()=>{
            }
        );
    }

    loadPartner(qrcode){
        this.crudService.api_get_PartnerQRCODE(qrcode).subscribe(productData => {
                this.detailPartner=productData;
                if(productData<0) {
                   this.partnerNonAbilitato();
                    return;
                }

                if(productData['isAutonomo']==0) {
                    this.partnerNonAbilitato();
                    return;
                }

                this.loadCorsiPartner(this.detailPartner['id_partner']);
            },(err)=>{
                //console.log(err);
                }, ()=>{
            }
        );
    }

    getDetailCorsista() {
        this.crudService.api_getDetailCorsista(this.idCorsistaDaAttivare).subscribe(productData => {
            console.log(productData);
            this.nomeCognomeCorsista=productData['cognome']+" "+productData['nome'];

            //invio l'email di conferma attivare al corsista
            var arrayDetail={'nome': productData['nome'],
                            'cognome': productData['cognome'],
                            'nomeCorso': this.titoloCorso,
                            'cf': productData['cf'],
                            'email': productData['email'],
                            'idPartner':this.detailPartner['id_partner']};

            //invio l'email al corsista
            if(this.isAddedCorsista=='1')
                this.sentEmailConfermaAttivazioneToCorsista(arrayDetail);
        },(err)=>{
            console.log(err);
            }, ()=>{
        }
        );
    }

    sentEmailConfermaAttivazioneToCorsista(arrayDetail) {
        this.crudService.sent_confermaIscrizioneCorsista(arrayDetail).subscribe(productData => {
            console.log(productData);
        });
    }

    addCorsistaInAula() {
        this.crudService.api_addCorsistaInAula(this.idCorsistaDaAttivare, this.detailPartner['id_partner'], this.idCorsoDaAttivare).subscribe(productData => {
            this.isAddedCorsista=productData.toString();
            this.getDetailCorsista();
        },(err)=>{
            console.log(err);
            }, ()=>{
        }
        );
    }

    submit(form){
        var dataNascita = form.dataNascita;
        var luogoNascita = form.luogoNascita;
        var indirizzo = form.indirizzo;
        var cap = form.cap;
        var citta = form.citta;
        var provincia = form.provincia;
        var name = form.name;
        var cognome = form.cognome;
        var email = form.email;
        var number = form.number;
        var sesso = form.sesso;
        var sceltaCorso = form.sceltaCorso;
        var cf=form.cf;

        var arrayDetail={
            'idPartner': this.detailPartner['id_partner'],
            'nome': name,
            'cognome': cognome,
            'sesso': sesso,
            'cf': cf,
            'indirizzo': indirizzo,
            'cap': cap,
            'citta': citta,
            'provincia': provincia,
            'luogoNascita': luogoNascita,
            'dataNascita': dataNascita,
            'email': email,
            'cellulare': number,
            'corsoScelto': sceltaCorso,
            'fileAllegato': this.fileName
        }

        if(name && cognome && email && number && luogoNascita && dataNascita && sceltaCorso) {
            this.crudService.sent_IscrizioneForPartner(arrayDetail).subscribe(responseData => {
                if(responseData==1) {
                    this.contactForm.reset();
                    this.emailSuccess();
                }
                if(responseData==2) {
                    this.accountEsistente();
                    return;
                }
                },(err)=>{
                    console.log("Errore:"+err);
                    }, ()=>{
                }
            );
        }
        else {
            this.errorForm();
        }
    }

    errorForm() {
        Swal.fire({
            title:'Campi Obbligatori!',
            text: 'bisogna compilare tutti i campi richiesti',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Grazie',
            allowOutsideClick: false,
         }).then((result) => {
            if (result.isConfirmed) {}
        });
    }

    emailSuccess() {
        Swal.fire({
            title:'Richiesta Inviata!',
            text: 'la tua richiesta è stat inviata con successo, sarai contattato dal nostro team il prima possibile',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Grazie',
            allowOutsideClick: false,
         }).then((result) => {
            if (result.isConfirmed) {
                location.href="/";
            }
        });
    }

    accountEsistente() {
        Swal.fire({
            title:'Account già presente!',
            text: 'i dati inseriti risultano già presente in archivio, contatta il tuo referente per il recupero dei dati per l\'accesso.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Riprova',
            allowOutsideClick: false,
         }).then((result) => {
            if (result.isConfirmed) {}
        });
    }

    partnerNonAbilitato() {
        Swal.fire({
            title:'Link non abilitato!',
            text: 'la pagina non è più disponibile, contattare il tuo referente per ulteriori informazioni.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#6993FF',
            cancelButtonColor: '#dadada',
            confirmButtonText: 'Riprova',
            allowOutsideClick: false,
         }).then((result) => {
            if (result.isConfirmed) {
                location.href="/";
            }
        });
    }

}
