<div class="container">
    <div class="section-title">
        <span class="sub-title">i corsi più richiesti</span>
        <h2>I più richiesti al momento</h2>
        <p>Esplora tutti i nostri corsi e scegli quelli adatti per iscriverti e iniziare a imparare con noi! Ti assicuriamo che non te ne pentirai mai!</p>
    </div>
    <div *ngIf="elencoCorsiInHome[0]" class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="single-courses-box">
                <div class="courses-image">
                    <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[0].tag}}" class="d-block image">
                        <img style="height:260px; object-fit: cover; width: 100%;" src="{{urlImage+elencoCorsiInHome[0].image}}" alt="image">
                    </a>
                    <div class="price shadow">{{elencoCorsiInHome[0].durata}}h</div>
                </div>
                <div class="courses-content">
                    <h3><a routerLink="/dettaglio-corso/31">{{elencoCorsiInHome[0].titolo}}</a></h3>
                    <p class="textDescrizione">{{elencoCorsiInHome[0].descrizione}}</p>
                    <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[0].tag}}" class="default-btn"><i class="flaticon-user"></i>Visualizza<span></span></a>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-courses-box">
                <div class="courses-image">
                    <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[1].tag}}" class="d-block image">
                        <img style="height:260px; object-fit: cover; width: 100%;" src="{{urlImage+elencoCorsiInHome[1].image}}" alt="image">
                    </a>
                    <div class="price shadow">{{elencoCorsiInHome[1].durata}}h</div>
                </div>
                <div class="courses-content">
                    <h3><a routerLink="/dettaglio-corso/31">{{elencoCorsiInHome[1].titolo}}</a></h3>
                    <p class="textDescrizione">{{elencoCorsiInHome[1].descrizione}}</p>
                    <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[1].tag}}" class="default-btn"><i class="flaticon-user"></i>Visualizza<span></span></a>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-courses-box">
                <div class="courses-image">
                    <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[2].tag}}" class="d-block image">
                        <img style="height:260px; object-fit: cover; width: 100%;" src="{{urlImage+elencoCorsiInHome[2].image}}" alt="image">
                    </a>
                    <div class="price shadow">{{elencoCorsiInHome[2].durata}}h</div>
                </div>
                <div class="courses-content">
                    <h3><a routerLink="/dettaglio-corso/31">{{elencoCorsiInHome[2].titolo}}</a></h3>
                    <p class="textDescrizione">{{elencoCorsiInHome[2].descrizione}}</p>
                    <a routerLink="/dettaglio-corso/{{elencoCorsiInHome[2].tag}}" class="default-btn"><i class="flaticon-user"></i>Visualizza<span></span></a>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="courses-info">
                <p>Goditi i migliori metodi di apprendimento e raggiungi abilità di livello superiore! Sei il creatore della tua carriera e noi ti guideremo attraverso questo. <a routerLink="/contatti">Registrati è Gratuito!</a>.</p>
            </div>
        </div>
    </div>
</div>
